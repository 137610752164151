import introJs from "intro.js";
import "intro.js/introjs.css";

const OnboardingSteps = () => {
  const steps = [
    {
      element: ".welcome-overlay",
      intro:
        "Welcome to DiligenceGPT! Here’s a quick guide to help you get started. Tap next to continue.",
      position: "center",
      disableInteraction: true,
    },
    {
      element: ".add-deal",
      intro:
        "Add new startup deals to your due diligence pipeline by clicking this button. Tap next to continue.",
      position: "bottom",
      disableInteraction: true,
    },
    {
      element: ".filter-width-main-wraper",
      intro:
        "Your deals will appear in this table. You can filter and manage your pipeline to stay organized. Click on any company name to begin the due diligence process.",
      position: "left",
      disableInteraction: false,
    },
  ];

  const startTour = () => {
    const intro = introJs();

    intro.setOptions({
      steps,
      exitOnOverlayClick: false,
      showStepNumbers: true,
      showBullets: true,
      showProgress: true,
      hideNext: false,
      hidePrev: false,
      nextLabel: "Next →",
      prevLabel: "← Back",
      doneLabel: "Finish",
      overlayOpacity: 0.5,
    });

    intro.onbeforechange((targetElement) => {
      console.log("About to show step for:", targetElement);
    });

    intro.oncomplete(() => {
      console.log("Tour completed");
    });

    intro.onexit(() => {
      console.log("Tour exited");
    });

    intro.start();
  };

  return { startTour };
};

const DilligenceSteps = () => {
  const steps = [
    {
      element: ".deal-info-main",
      intro:
        "This is the data room of the deal. We recommend that you add a pitch deck or business plan in order to start using your AI credits. You can add multiple documents to each deal or invite the founder to upload their files. Tap next to continue",
      position: "left",
    },
    {
      element: ".review-submenu-list",
      intro:
        "You can review or edit the information that is extracted in each tab. The more details completed in each section will provide more accurate results when generating reports.",
      position: "left",
    },
    {
      element: ".chat-container",
      intro:
        "You can ask specific questions about a deal and where the information is sourced from in the data room.",
      position: "left",
    },
    {
      intro:
        "This is where you can generate AI reports for each company. We recommend a data sufficiency score above 75% before running the startup score.",
      position: "left",
    },
  ];

  const startTour = () => {
    const intro = introJs();

    intro.setOptions({
      steps,
      exitOnOverlayClick: false,
      showStepNumbers: true,
      showBullets: true,
      showProgress: true,
      hideNext: false,
      hidePrev: false,
      nextLabel: "Next →",
      prevLabel: "← Back",
      doneLabel: "Finish",
      overlayOpacity: 0.5,
    });

    intro.onbeforechange((targetElement) => {
      console.log("About to show step for:", targetElement);
      if (intro.currentStep() === 3) {
        console.log("Clicking on report tab");
        const tabButton = document.getElementById("tab_btn_1");
        if (tabButton) {
          tabButton.click();
          console.log("Clicked tab button");
          setTimeout(() => {
            const modalInner = document.querySelector(
              ".info_review_modal_inner",
            );
            if (modalInner) {
              modalInner.scrollTop = 0;
              console.log("Scrolled to top");
            } else {
              console.log("Modal inner element not found");
            }
          }, 100);
        }
      }
    });

    intro.oncomplete(() => {
      console.log("Tour completed");
    });

    intro.onexit(() => {
      console.log("Tour exited");
    });

    intro.start();
  };

  return { startTour };
};

export { OnboardingSteps, DilligenceSteps };
