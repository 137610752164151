import React, { useState, useEffect } from "react";
import DealInfoComponent from "./dealInfoComponent";
import Submenu from "../submenu";
import ApplicationInfoComponent from "./applicationInfoComponent";
import DataRoomComponent from "./dataRoomComponent";
import ChatLayout from "../reviewsCardContainer";
import WebsiteInfoComponent from "./websiteInfo";
import DealWallComponent from "./dealWallComponent";

const DealInfoMain = ({
  companyInfo,
  applicationObject,
  files,
  deleteFile,
  getFiles,
  updateCompanyData,
  opportunity_data,
  handleCreditChange,
  user,
}) => {
  const menuItems = [
    "Data Room",
    "Company Info",
    "Deal Info",
    "Website Info",
    "Deal Wall",
  ];
  const [activeItem, setActiveItem] = useState("Data Room");
  const [submenuState, setSubmenuState] = useState("approved");

  useEffect(() => {
    setSubmenuState(getSubmenuState());
  }, [companyInfo?.deal_info]);

  const getSubmenuState = () => {
    if (!companyInfo?.deal_info) return null;

    // Filter out company_name field if it exists
    const filteredDealInfo = companyInfo.deal_info.filter(
      (field) => field.field_name !== "company_name",
    );

    // Check if array length is less than 5
    if (filteredDealInfo.length < 5) {
      return "declined";
    }

    // Check if any fields are declined
    const declinedField = filteredDealInfo.find(
      (field) => field.field_state === "declined",
    );
    if (declinedField) {
      console.log("Field with declined state:", declinedField.field_name);
      return "declined";
    }

    const pendingField = filteredDealInfo.find(
      (field) => field.field_state === "pending",
    );
    if (pendingField) {
      console.log("Field with pending state:", pendingField.field_name);
      return "pending";
    }

    return "approved";
  };

  const renderActiveComponent = () => {
    switch (activeItem) {
      case "Data Room":
        return (
          <DataRoomComponent
            files={files}
            deleteFile={deleteFile}
            companyInfo={companyInfo}
            getFiles={getFiles}
            updateCompanyData={updateCompanyData}
          />
        );
      case "Company Info":
        return (
          <DealInfoComponent
            companyInfo={companyInfo}
            user={user}
            updateCompanyData={updateCompanyData}
          />
        );
      case "Deal Info":
        return (
          <ApplicationInfoComponent
            applicationObject={applicationObject}
            companyInfo={companyInfo}
            opportunity_data={opportunity_data}
            updateCompanyData={updateCompanyData}
          />
        );
      case "Website Info":
        return (
          <WebsiteInfoComponent
            companyInfo={companyInfo}
            opportunity_data={opportunity_data}
            updateCompanyData={updateCompanyData}
          />
        );
      case "Deal Wall":
        return (
          <DealWallComponent
            companyInfo={companyInfo}
            opportunity_data={opportunity_data}
            updateCompanyData={updateCompanyData}
            userId={user._id}
          />
        );
      default:
        return null;
    }
  };

  return (
    <ChatLayout
      companyInfo={companyInfo}
      opportunity_data={opportunity_data}
      updateCompanyData={updateCompanyData}
      applicationObject={applicationObject}
      handleCreditChange={handleCreditChange}
      user={user}
    >
      <div className="deal-info-main deal-info-container">
        <Submenu
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          menuItems={menuItems}
          state={submenuState}
        />
        <div className="deal-info">{renderActiveComponent()}</div>
      </div>
    </ChatLayout>
  );
};

export default DealInfoMain;
